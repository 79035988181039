import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Psycholog - Psychoterapeuta Tarnowskie Góry" />
    <h1>Psycholog - Psychoterapeuta</h1>
    <p>Psychoterapia zaburzeń nastroju, zaburzeń związanych ze stresem, zaburzeń lękowych, fobii. Wsparcie terapeutyczne w kryzysach, zaburzeniach psychotycznych</p>
    <p>Tarnowskie Góry ul. Wajdy 5/1</p>
    <p>tel. 608 247 086</p>
    <p>godz. 14-19</p>
    <StaticImage
      src="../images/hand.jpg"
      width={300}
      quality={95}
      formats={["auto", "webp", "avif"]}
      alt="Talk"
      style={{ marginBottom: `1.45rem` }}
    />

    <p>
      <Link to="/walus/">mgr Bożena Waluś</Link> <br />
      <Link to="/sokolowska-huss/">mgr Katarzyna Sokołowska-Huss</Link> <br />
      <a href="http://psychiatra-tg.pl/">Psychiatra-Seksuolog dr n. med. Michał Błachut</a>
    </p>

    <p>Pomoc psychologiczna</p>
    <p>Psychoterapia poznawczo-behawioralna</p>
    <p>Wsparcie terapeutyczne</p>
    <p>Interwencja kryzysowa</p>
    <p>Psychoedukacja </p>

    
  </Layout>
)

export default IndexPage
